import uuid from 'react-uuid';
import moment from 'moment-timezone';
import isEmpty from 'lodash.isempty';

export const FILE_TYPES = [
  { value: 'Form', label: 'Form' },
  { value: 'Pdf', label: 'Pdf' },
  { value: 'Register', label: 'Register' },
];

export const DOCUMENT_TYPES = [
  { value: "Register", label: "Register" },
  { value: "Form", label: "Form" },
  { value: "Pdf", label: "PDF" },
];

export const arrayToHash = (array) => {
  let hash = {};
  array.map((obj, index) => {
    hash = { ...hash, [index]: obj };
  });
  return hash;
};

export const hashToArray = (hash) => {
  let array = [];
  Object.keys(hash).map((index) => {
    array.push(hash[index]);
  });
  return array;
};

export const DOCUMENT_STATUSES = [
  { value: 'available', label: 'Available' },
  { value: 'notapplicable', label: 'Not Applicable' },
  { value: 'need_attention', label: 'Need Attention' },
  { value: 'review_required', label: 'Review Required' },
  { value: 'actioned', label: 'Actioned' },
  { value: 'assigned', label: 'Assigned' },
  { value: 'uploaded', label: 'Uploaded' },
  { value: 'completed', label: 'Completed' },
];

export const TASK_STATUSES = [
  { value: 'pending', label: 'Pending' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'completed', label: 'Completed' },
  { value: 'closed', label: 'Closed' },
];

export const REGISTER_STATUSES = TASK_STATUSES;

export const SIGN_DOCUMENT_REVIEW = 'review';
export const SIGN_DOCUMENT_COMPLETED = 'completed';
export const SIGNED_DOCUMENT_STATUS = {
  inReview: "in - review",
  completed: "completed",
}

export const queryStringToObject = (queryString) => {
  const params = new URLSearchParams(queryString);
  const object = {};
  for (let p of params) {
    object[p[0]] = p[1];
  }
  return object;
};

export const titleCase = (str) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const getClientId = (pathName) => {
  let clientId = '';
  let path = pathName.match(/clients\/\d+\//);
  if (Array.isArray(path)) {
    clientId = path[0].replace(/clients\//, '').replace('/', '');
  }
  return clientId;
};

export const DEFAULT_MULTIPLE_CHOICE_BUTTONS_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
    color: '#fff',
  },
  {
    label: 'No',
    value: 'no',
    color: '#fff',
  },
  {
    label: 'N/A',
    value: 'na',
    color: '#fff',
  },
];

export const DEFAULT_MULTIPLE_CHOICE_SELECT_OPTIONS = [
  {
    id: uuid(),
    label: 'Option 1',
    value: '1',
  },
];

export const DEFAULT_MULTIPLE_CHOICE_CHECKBOXES_OPTIONS = [
  {
    id: uuid(),
    label: 'Label 1',
    value: '1',
  },
];

export const getChildFields = (inputFields, field) => {
  return inputFields.filter((f) => f.parent_id == field.uuid);
};

export const getParentField = (inputFields, field) => {
  if (!field.parent_id && !Array.isArray(inputFields)) return null;
  const index = inputFields.findIndex((f) => f.uuid == field.parent_id);
  if (index < 0) return null;
  return inputFields[index];
};

export const childFieldsCount = (inputFields, field) => {
  const childFields = getChildFields(inputFields, field);

  const childCounts = childFields.map((childField) => {
    return childFieldsCount(inputFields, childField);
  });

  const totalChildCount = childCounts.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  return childFields.length + totalChildCount;
};

export const nextFieldPosition = (inputFields, field) => {
  if (field) {
    const { position } = field;

    return position + childFieldsCount(inputFields, field) + 1;
  } else {
    if (inputFields.length < 1) return 1;
    return inputFields[inputFields.length - 1].position + 1;
  }
};

export const fieldNestedLevel = (inputFields, field) => {
  let level = 0;
  let parentField = getParentField(inputFields, field);
  while (parentField) {
    level++;
    parentField = getParentField(inputFields, parentField);
  }
  return level;
};

export const canAddLogicField = (inputFields, field) => {
  if (
    (field.field_type === 'multiple_choice_select' ||
      field.field_type === 'multiple_choice_buttons' ||
      field.field_type === 'multiple_choice_radio') &&
    getChildFields(inputFields, field).length <
      field.options.multiple_choice_options.length
  ) {
    return true;
  }
  return false;
};

export const nextResponseAvailable = (inputFields, field) => {
  let allOptions = field.options.multiple_choice_options.map(
    (option) => option.label
  );
  const usedOptions = usedResponses(inputFields, field);
  allOptions = allOptions.filter(function (option) {
    return usedOptions.indexOf(option) < 0;
  });
  return allOptions[0];
};

export const usedResponses = (inputFields, field) => {
  const childFields = inputFields.filter(
    (f) => f.parent_id == field.uuid && f.field_type == 'logic'
  );
  return childFields.map((f) => {
    if (f.options.selected_options) return f.options.selected_options[0];
  });
};

export const availableResponses = (inputFields, field) => {
  const parentField = getParentField(inputFields, field);
  let allOptions = parentField.options.multiple_choice_options.map(
    (option) => option.label
  );
  // const usedOptions = usedResponses(inputFields, parentField);
  // allOptions = allOptions.filter( function( option ) {
  //   return usedOptions.indexOf( option ) < 0;
  // });
  return allOptions;
};

export const hideLogicNestedField = (inputFields, field) => {
  if (!field.parent_id) return false;
  const parentField = getParentField(inputFields, field);
  if (parentField.field_type == 'logic') {
    const parentOfLogic = getParentField(inputFields, parentField);
    if (parentOfLogic.options.multiple_choice_responses) {
      if (
        parentOfLogic.options.multiple_choice_responses[0] ==
        parentField.options.selected_options[0]
      ) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export const resetPositions = (inputFields) => {
  return inputFields.map((field, index) => ({ ...field, position: index + 1 }));
};

export const hasChildren = (inputFields, field) => {
  return getChildFields(inputFields, field).length > 0;
};

export const fieldsOptions = [
  {
    fieldName: 'Heading',
    fieldLabel: 'Type Heading',
    fieldType: 'heading',
    disableForGroupField: true,
    icon: 'mdi mdi-signal-hspa',
  },
  {
    fieldName: 'Rich Text',
    fieldLabel: 'Type Text',
    fieldType: 'rich_text',
    disableForGroupField: true,
    icon: 'mdi mdi-format-bold',
  },
  {
    fieldName: 'Section',
    fieldLabel: 'Type Section Heading',
    fieldType: 'section',
    disableForGroupField: true,
    icon: 'mdi mdi-format-paragraph',
  },
  {
    fieldName: 'Single Line Text',
    fieldLabel: 'Type Single Line Text',
    fieldType: 'text',
    icon: 'mdi mdi-text-box',
  },
  {
    fieldName: 'Multi Line Text',
    fieldLabel: 'Type Multi Line Text',
    fieldType: 'textarea',
    icon: 'mdi mdi-text-box-plus',
  },
  {
    fieldName: 'Field Group',
    fieldLabel: 'Field Group',
    fieldType: 'field_group',
    disableForGroupField: true,
    icon: 'mdi mdi-text-box-multiple',
  },
  {
    fieldName: 'Date',
    fieldLabel: 'Date',
    fieldType: 'date',
    icon: 'mdi mdi-calendar',
  },
  {
    fieldName: 'Time',
    fieldLabel: 'Time',
    fieldType: 'time',
    icon: 'mdi mdi-clock',
  },
  {
    fieldName: 'Multiple Choice Buttons',
    fieldLabel: 'Type Question',
    fieldType: 'multiple_choice_buttons',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Multiple Choice Select',
    fieldLabel: 'Type Question',
    fieldType: 'multiple_choice_select',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Single Checkbox',
    fieldLabel: 'Type Question',
    fieldType: 'checkbox',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Checkbox List',
    fieldLabel: 'Type Question',
    fieldType: 'multiple_choice_checkboxes',
    icon: 'mdi mdi-checkbox-multiple-marked',
  },
  {
    fieldName: 'Radio Buttons',
    fieldLabel: 'Type Question',
    fieldType: 'multiple_choice_radio',
    icon: 'mdi mdi-radiobox-marked',
  },
  {
    fieldName: 'Signature',
    fieldLabel: 'Signature',
    fieldType: 'signature',
    icon: 'mdi mdi-draw',
  },
  {
    fieldName: 'Upload File',
    fieldLabel: 'Upload File',
    fieldType: 'file',
    icon: 'mdi mdi-upload',
  },
  {
    fieldName: 'Attachments',
    fieldLabel: 'Attachments',
    fieldType: 'file_attacher',
    icon: 'mdi mdi-upload',
  },
];

export const fieldLabelOptions = [
  {
    fieldName: 'Heading',
    fieldType: 'heading',
    icon: 'mdi mdi-signal-hspa',
  },
  {
    fieldName: 'Rich Text',
    fieldType: 'rich_text',
    icon: 'mdi mdi-format-bold',
  },
  {
    fieldName: 'Section',
    fieldType: 'section',
    icon: 'mdi mdi-format-paragraph',
  },
  {
    fieldName: 'Single Line Text',
    fieldType: 'text',
    icon: 'mdi mdi-text-box',
  },
  {
    fieldName: 'Multi Line Text',
    fieldType: 'textarea',
    icon: 'mdi mdi-text-box-plus',
  },
  {
    fieldName: 'Field Group',
    fieldType: 'field_group',
    icon: 'mdi mdi-text-box-multiple',
  },
  {
    fieldName: 'Date',
    fieldType: 'date',
    icon: 'mdi mdi-calendar',
  },
  {
    fieldName: 'Time',
    fieldType: 'time',
    icon: 'mdi mdi-clock',
  },
  {
    fieldName: 'Multiple Choice Buttons',
    fieldType: 'multiple_choice_buttons',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Multiple Choice Select',
    fieldType: 'multiple_choice_select',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Single Checkbox',
    fieldType: 'checkbox',
    icon: 'mdi mdi-checkbox-marked',
  },
  {
    fieldName: 'Checkbox List',
    fieldType: 'multiple_choice_checkboxes',
    icon: 'mdi mdi-checkbox-multiple-marked',
  },
  {
    fieldName: 'Radio Buttons',
    fieldType: 'multiple_choice_radio',
    icon: 'mdi mdi-radiobox-marked',
  },
  {
    fieldName: 'Signature',
    fieldType: 'signature',
    icon: 'mdi mdi-draw',
  },
  {
    fieldName: 'Upload File',
    fieldType: 'file',
    icon: 'mdi mdi-upload',
  },
  {
    fieldName: 'Attachments',
    fieldType: 'file_attacher',
    icon: 'mdi mdi-upload',
  },
  {
    fieldName: 'Logic',
    fieldType: 'logic',
    icon: 'mdi mdi-transit-detour',
  },
];

export const getFieldLabel = (field) => {
  const fieldIndex = fieldLabelOptions.findIndex(
    (f) => f.fieldType == field.field_type
  );
  if (!fieldLabelOptions[fieldIndex]) {
    return 'Field';
  }
  return fieldLabelOptions[fieldIndex].fieldName;
};

export const getFieldIcon = (field) => {
  const fieldIndex = fieldLabelOptions.findIndex(
    (f) => f.fieldType == field.field_type
  );
  if (!fieldLabelOptions[fieldIndex]) {
    return 'mdi mdi-text-box';
  }
  return fieldLabelOptions[fieldIndex].icon;
};

export const makeBadgePillColor = (documentType) => {
  switch (documentType) {
    case 'Form':
      return 'badge-soft-primary';
    case 'Folder':
      return 'badge-soft-secondary';
    case 'PDF':
      return 'badge-soft-danger';
    case 'Register':
      return 'badge-soft-info';
    case 'in-review':
      return 'badge-soft-secondary';
    default:
      return 'badge-soft-secondary';
  }
};

export const getTimeDifference = (commentTimeZone) => {
  let today = new Date();
  let commentDate = new Date(commentTimeZone);
  let diff = Math.abs(commentDate - today);

  let minutes = Math.floor((diff / (1000 * 60)) % 60),
    hours = Math.floor((diff / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  let timeInHour = hours + ':' + minutes;
  var timeParts = timeInHour.split(':');
  let timeInMinutes = timeParts[0] * 60 + Number(timeParts[1]);
  let days = { weekday: 'long' };
  var months = { month: 'long' };
  let dayName = new Intl.DateTimeFormat('en-AU', days)
    .format(commentDate)
    .slice(0, 3);
  let monthName = new Intl.DateTimeFormat('en-US', months)
    .format(commentDate)
    .slice(0, 3);
  let date = commentDate.getDate();
  let year = commentDate.getFullYear();
  let timeOfComment = dayName + ', ' + date + ' ' + monthName + ' ' + year;
  let noOfdays = moment().diff(moment(commentDate), 'days');

  if (hours === '00') {
    return minutes + ' min';
  } else if (noOfdays > 1) {
    return timeOfComment;
  } else if (timeInMinutes > 60) {
    return hours + ' hours ago';
  } else {
    return timeInMinutes + ' min';
  }
};

export const dayWithDate = (date, dateOnly = false) => {
  const today = new Date();
  const commentDate = new Date(date);
  const dayName = commentDate.toLocaleDateString('en-AU', { weekday: 'long' });
  const monthName = commentDate.toLocaleDateString('en-AU', { month: 'long' });
  const monthDate = commentDate.getDay();
  let dayPostFix = monthDate == 1 ? 'st' : monthDate == 2 ? 'nd' : 'th';

  let diff = Math.abs(today.getDate() - commentDate.getDate());
  if (diff == 0) {
    return 'Today';
  } else if (diff == 1) {
    return 'Yesterday';
  } else if (diff > 1) {
    if (dateOnly) {
      return monthName + ' ' + monthDate + dayPostFix;
    }
    return dayName + ', ' + monthName + ' ' + monthDate + dayPostFix;
  }
};

export const makePostData = (inputFields) => {
  const postData = [];
  inputFields.map((input) => {
    if (
      input.field_type == 'multiple_choice_buttons' ||
      input.field_type == 'multiple_choice_select' ||
      input.field_type == 'multiple_choice_checkboxes' ||
      input.field_type == 'multiple_choice_radio'
    ) {
      if (input.options.multiple_choice_responses) {
        postData.push({
          id: input.id,
          value: input.options.multiple_choice_responses
            ? JSON.stringify(input.options.multiple_choice_responses)
            : '',
          field_name: input.field_name,
        });
      }
    } else if (input.value) {
      postData.push({
        id: input.id,
        value: input.value,
        field_name: input.field_name,
      });
    }
  });
  return postData;
};

export const dasherize = (str) => {
  if (isEmpty(str)) return '';

  return str
    .toLowerCase()
    .replace(/[^a-zA-Z\d]+/g, '-')
    .replace(/\s+/g, '-');
};

export const stepList = {
  step01: 1,
  step02: 2,
  step03: 3,
  step04: 4,
  step05: 5,
  step06: 6
};

export const FLOW_CREATE_USER = 'flow-create-user';
export const FLOW_CREATE_ROLE = 'flow-create-role';

export const TYPE_TABLE = {
  TASK: 'task',
  QUICK_ACTION: 'quick-action',
  ROLE_LIST: 'role-list',
  SIGNED_DOCUMENTS: 'signed-documents'
};

export const BIZPORT_SERVICES = 'BizPort Services'

export const SORT_TYPE = {
  DESC: "desc",
  ASC: "asc",
}
export const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required.'

export const CREATED_MESSAGE = 'Created successfully!'

export const UPDATED_MESSAGE = 'Updated successfully!'

export const DELETED_MESSAGE = 'Deleted successfully!'

export function appendToFormData(formData, data, keyPrefix = "") {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const fullKey = keyPrefix ? `${keyPrefix}[${key}]` : key;
      const value = data[key];

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (Array.isArray(value)) {
        if (Array.isArray(value[0]) || typeof value[0] === "object") {
          value.forEach((item, index) => {
            appendToFormData(formData, item, `${fullKey}[${index}]`);
          });
        } else {
          value.forEach((item) => {
            formData.append(`${fullKey}[]`, item);
          });
        }
      } else if (typeof value === "object") {
        appendToFormData(formData, value, fullKey);
      } else {
        formData.append(fullKey, value);
      }
    }
  }
};

export const filterNodes = (node) => {
  if (node.children && node.children.length === 0) {
    return null;
  }
  if (node.children) {
    node.children = [...node.children.map(filterNodes).filter(Boolean)];
  }
  return node;
}

export const formatTime = (string) => {
  const date = new Date(string);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minus = date.getMinutes();
  const time = string.substring(string.length - 2, string.length);
  return `${day}/${month}/${year}. ${hour}:${minus} ${time}`;
};

export const formatDate = (string) => {
  if (!string) return "";
  var date = new Date(string);
  return date.toLocaleDateString("en-GB");
};
